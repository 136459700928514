<template>
  <component :is="$route.meta.layout || 'div'">
    <Toast />
    <nav-bar
      v-if="auth.isAuthenticated"
      :messages="messages"
      @reads="onMessageReads"
      @clear="onMessageClear"
    />
    <router-view class="m-4" />
    <div class="text-xs ml-4 mb-4">FarmaNex v{{ appVersion }}</div>
    <Dialog
      header="Update tersedia"
      v-model:visible="refreshDialog"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :modal="true"
      :closable="false"
      position="bottom"
    >
      <p class="m-0">
        Update aplikasi telah tersedia. Klik tombol Refresh untuk memperbaharui.
      </p>
      <template #footer>
        <Button
          label="Refresh"
          icon="pi pi-check"
          @click="updateApp"
          autofocus
        />
      </template>
    </Dialog>
    <ScrollTop />
  </component>
</template>

<script>
import ScrollTop from 'primevue/scrolltop'
import NavBar from './components/NavBar.vue'
import errorHandler from '@/helpers/error-handler'
import { getToken, onMessage } from 'firebase/messaging'
import { messaging } from '@/firebase.js'
import { useAuthStore } from '@/store/auth'

export default {
  components: { NavBar, ScrollTop },
  inject: ['workbox', 'messaging', 'ability'],
  created() {
    this.auth = useAuthStore()
    if (this.workbox) {
      this.workbox.addEventListener('waiting', () => {
        this.refreshDialog = true
      })
    }
  },
  data() {
    return {
      refreshDialog: false,
      messages: [],
      auth: {},
      appVersion: process.env.VUE_APP_VERSION,
    }
  },
  mounted() {
    const messages = JSON.parse(localStorage.getItem('messages'))
    if (messages) {
      this.messages = messages
    }
    getToken(messaging, {
      vapidKey:
        'BMgZB8alDPxtvKl6sx9dF4WoYb1Ryn3Bz-fJzNaTKgKzqQYAqGzm7JZqyV6uCQ-7u1AYJmA0M6XfyF1Xr36uk3I',
    }).then((currentToken) => {
      if (currentToken) {
        this.receiveMessage()
      }
    })

    this.auth.refreshPermission(this.ability)
  },
  methods: {
    async updateApp() {
      this.refreshDialog = false
      await this.workbox.messageSW({ type: 'SKIP_WAITING' })
    },
    receiveMessage() {
      try {
        onMessage(messaging, (payload) => {
          const notification = payload.notification
          const message = {
            id: this.messages.length + 1,
            title: notification.title,
            body: notification.body,
            reads: 0,
          }
          this.$toast.add({
            severity: 'info',
            summary: message.title,
            detail: message.body,
          })
          this.messages.push(message)
          localStorage.setItem('messages', JSON.stringify(this.messages))
        })
      } catch (err) {
        errorHandler(err, 'An error occurred while receiving message.', this)
      }
    },
    onMessageReads(data) {
      let changes = false
      this.messages.forEach((item) => {
        if (item.id === data.id) {
          item.reads = data.reads
          changes = true
        }
      })
      if (changes) {
        localStorage.setItem('messages', JSON.stringify(this.messages))
      }
    },
    onMessageClear() {
      this.messages = []
      localStorage.setItem('messages', JSON.stringify(this.messages))
    },
  },
}
</script>
