import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import EmptyLayout from '../layouts/Empty.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: { title: 'Halaman tidak ditemukan !' },
  },
  {
    path: '/gudang/barang-keluar',
    name: 'GudangKeluar',
    component: () =>
      import(
        /* webpackChunkName: "gudang" */ '@/views/gudang/keluar/Index.vue'
      ),
    meta: { auth: true, title: 'Barang Keluar' },
  },
  {
    path: '/gudang/barang-masuk',
    name: 'GudangMasuk',
    component: () =>
      import(/* webpackChunkName: "gudang" */ '@/views/gudang/masuk/Index.vue'),
    meta: { auth: true, title: 'Barang Masuk' },
  },
  {
    path: '/gudang/stok-opname',
    name: 'StokOpname',
    component: () =>
      import(
        /* webpackChunkName: "gudang" */ '@/views/gudang/stok-opname/Index.vue'
      ),
    meta: { auth: true, title: 'Stok Opname' },
  },
  {
    path: '/gudang/ganti-batch',
    name: 'StokOpnameGantiBatch',
    component: () =>
      import(
        /* webpackChunkName: "GantiBatch" */ '@/views/gudang/ganti-batch/Index.vue'
      ),
    meta: { auth: true, title: 'Ganti Nomor Batch' },
  },
  /* {
    path: '/gudang/stok-opname/add',
    name: 'StokOpnameAdd',
    component: () =>
      import(
        webpackChunkName: "pembelian"  '@/views/gudang/stok-opname/Set.vue'
      ),
    meta: { auth: true, title: 'Stok Opname' },
  }, */
  {
    path: '/gudang/stok-opname/edit/:id',
    name: 'StokOpnameEdit',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/gudang/stok-opname/Set.vue'
      ),
    meta: { auth: true, title: 'Stok Opname' },
  },
  {
    path: '/',
    name: 'Home',
    // component: () => import('@/views/Home.vue'),
    component: Home,
    meta: { auth: true, title: 'Beranda' },
  },
  {
    path: '/laporan/apoteker',
    name: 'Laporan apoteker',
    component: () =>
      import(/* webpackChunkName: "laporan" */ '@/views/laporan/Apoteker.vue'),
    meta: { auth: true, title: 'Laporan apoteker' },
  },
  {
    path: '/laporan/salesman',
    name: 'Laporan Salesman',
    component: () =>
      import(/* webpackChunkName: "laporan" */ '@/views/laporan/Salesman.vue'),
    meta: { auth: true, title: 'Laporan salesman' },
  },
  {
    path: '/laporan/penjualan',
    name: 'Laporan Penjualan Per Pelanggan',
    component: () =>
      import(/* webpackChunkName: "laporan" */ '@/views/laporan/Penjualan.vue'),
    meta: { auth: true, title: 'Laporan Penjualan' },
  },
  {
    path: '/laporan/pembelian',
    name: 'Laporan Pembelian',
    component: () =>
      import(/* webpackChunkName: "laporan" */ '@/views/laporan/Pembelian.vue'),
    meta: { auth: true, title: 'Laporan Pembelian' },
  },
  {
    path: '/laporan/hutang-usaha',
    name: 'Laporan Hutang Usaha',
    component: () =>
      import(
        /* webpackChunkName: "laporan" */ '@/views/laporan/HutangUsaha.vue'
      ),
    meta: { auth: true, title: 'Laporan Hutang Usaha' },
  },
  {
    path: '/laporan/piutang-usaha',
    name: 'Laporan Piutang Usaha',
    component: () =>
      import(
        /* webpackChunkName: "laporan" */ '@/views/laporan/PiutangUsaha.vue'
      ),
    meta: { auth: true, title: 'Laporan Piutang Usaha' },
  },
  {
    path: '/laporan/persediaan-barang',
    name: 'Laporan Persediaan Barang',
    component: () =>
      import(
        /* webpackChunkName: "laporan" */ '@/views/laporan/PersediaanBarang.vue'
      ),
    meta: { auth: true, title: 'Laporan Persediaan Barang' },
  },
  {
    path: '/login',
    name: 'Login',
    // component: () => import('@/views/Login.vue'),
    component: Login,
    meta: { title: 'Login', layout: EmptyLayout },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () =>
      import(/* webpackChunkName: "create" */ '@/views/Logout.vue'),
    meta: { auth: true, layout: EmptyLayout },
  },
  {
    path: '/keuangan/head-account',
    name: 'HeadAccount',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/HeadAccount.vue'
      ),
    meta: { auth: true, title: 'Head Account' },
  },
  {
    path: '/keuangan/kelompok-account',
    name: 'KelompokAccount',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/KelompokAccount.vue'
      ),
    meta: { auth: true, title: 'Kelompok Account' },
  },
  {
    path: '/keuangan/sub-kelompok-account',
    name: 'SubKelompokAccount',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/SubKelompokAccount.vue'
      ),
    meta: { auth: true, title: 'Kelompok Account' },
  },
  {
    path: '/keuangan/perkiraan-akun',
    name: 'PerkiraanAkun',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/PerkiraanAkun.vue'
      ),
    meta: { auth: true, title: 'Perkiraan Akun' },
  },
  {
    path: '/keuangan/neraca-awal',
    name: 'NeracaAwal',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/NeracaAwal.vue'
      ),
    meta: { auth: true, title: 'Neraca Awal' },
  },
  {
    path: '/keuangan/saldo/hutang',
    name: 'SaldoAwalHutang',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/saldo/hutang/Index.vue'
      ),
    meta: { auth: true, title: 'Saldo Awal Hutang' },
  },
  {
    path: '/keuangan/saldo/hutang/add',
    name: 'SaldoAwalHutangAdd',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/keuangan/saldo/hutang/Set.vue'
      ),
    meta: { auth: true, title: 'Saldo Awal Hutang' },
  },
  {
    path: '/keuangan/saldo/hutang/:id',
    name: 'SaldoAwalHutangEdit',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/keuangan/saldo/hutang/Set.vue'
      ),
    meta: { auth: true, title: 'Saldo Awal Hutang' },
  },
  {
    path: '/keuangan/saldo/piutang',
    name: 'SaldoAwalPiutang',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/saldo/piutang/Index.vue'
      ),
    meta: { auth: true, title: 'Saldo Awal Piutang' },
  },
  {
    path: '/keuangan/saldo/piutang/add',
    name: 'SaldoAwalPiutangAdd',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/keuangan/saldo/piutang/Set.vue'
      ),
    meta: { auth: true, title: 'Saldo Awal Piutang' },
  },
  {
    path: '/keuangan/saldo/piutang/:id',
    name: 'SaldoAwalPiutangEdit',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/keuangan/saldo/piutang/Set.vue'
      ),
    meta: { auth: true, title: 'Saldo Awal Piutang' },
  },
  {
    path: '/keuangan/saldo/kas',
    name: 'SaldoAwalKas',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/saldo/kas/Index.vue'
      ),
    meta: { auth: true, title: 'Saldo Awal Kas' },
  },
  {
    path: '/keuangan/saldo/barang',
    name: 'SaldoAwalBarang',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/saldo/barang/Index.vue'
      ),
    meta: { auth: true, title: 'Saldo Awal Barang' },
  },
  {
    path: '/keuangan/penerimaan',
    name: 'CashIn',
    component: () =>
      import(
        /* webpackChunkName: "penerimaan" */ '@/views/keuangan/penerimaan/Index.vue'
      ),
    meta: { auth: true, title: 'Penerimaan Kas/Bank' },
  },
  {
    path: '/keuangan/penerimaan/add',
    name: 'CashInAdd',
    component: () =>
      import(
        /* webpackChunkName: "penerimaanAdd" */ '@/views/keuangan/penerimaan/Set.vue'
      ),
    meta: { auth: true, title: 'Penerimaan Kas/Bank' },
  },
  {
    path: '/keuangan/penerimaan/edit/:id',
    name: 'CashInEdit',
    component: () =>
      import(
        /* webpackChunkName: "penerimaanEdit" */ '@/views/keuangan/penerimaan/Set.vue'
      ),
    meta: { auth: true, title: 'Penerimaan Kas/Bank' },
  },
  {
    path: '/keuangan/pengeluaran',
    name: 'CashOut',
    component: () =>
      import(
        /* webpackChunkName: "pengeluaran" */ '@/views/keuangan/pengeluaran/Index.vue'
      ),
    meta: { auth: true, title: 'Pengeluaran Kas/Bank' },
  },
  {
    path: '/keuangan/pengeluaran/add',
    name: 'CashOutAdd',
    component: () =>
      import(
        /* webpackChunkName: "pengeluaranAdd" */ '@/views/keuangan/pengeluaran/Set.vue'
      ),
    meta: { auth: true, title: 'Pengeluaran Kas/Bank' },
  },
  {
    path: '/keuangan/pengeluaran/edit/:id',
    name: 'CashOutEdit',
    component: () =>
      import(
        /* webpackChunkName: "pengeluaranEdit" */ '@/views/keuangan/pengeluaran/Set.vue'
      ),
    meta: { auth: true, title: 'Pengeluaran Kas/Bank' },
  },
  {
    path: '/keuangan/transfer',
    name: 'TransferKas',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/transfer/Index.vue'
      ),
    meta: { auth: true, title: 'Transfer Kas' },
  },
  {
    path: '/keuangan/giro-masuk',
    name: 'GiroMasuk',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/giro-masuk/Index.vue'
      ),
    meta: { auth: true, title: 'Giro Masuk' },
  },
  {
    path: '/keuangan/giro-keluar',
    name: 'GiroKeluar',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/giro-keluar/Index.vue'
      ),
    meta: { auth: true, title: 'Giro Keluar' },
  },
  {
    path: '/keuangan/jurnal-umum',
    name: 'JurnalUmum',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/jurnal-umum/Index.vue'
      ),
    meta: { auth: true, title: 'Jurnal Umum' },
  },
  {
    path: '/keuangan/jurnal-umum/add',
    name: 'JurnalUmumAdd',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/jurnal-umum/Set.vue'
      ),
    meta: { auth: true, title: 'Jurnal Umum' },
  },
  {
    path: '/keuangan/jurnal-umum/edit/:id',
    name: 'JurnalUmumEdit',
    component: () =>
      import(
        /* webpackChunkName: "akunting" */ '@/views/keuangan/jurnal-umum/Set.vue'
      ),
    meta: { auth: true, title: 'Jurnal Umum' },
  },
  {
    path: '/keuangan/laporan',
    name: 'CashOutReport',
    component: () =>
      import(
        /* webpackChunkName: "pengeluaran-laporan" */ '@/views/keuangan/Laporan.vue'
      ),
    meta: { auth: true, title: 'Laporan Kas/Bank' },
  },
  {
    path: '/master/area',
    name: 'Area',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/Area.vue'),
    meta: { auth: true, title: 'Area' },
  },
  {
    path: '/master/daftar-kas',
    name: 'DaftarKas',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/DaftarKas.vue'),
    meta: { auth: true, title: 'Daftar kas' },
  },
  {
    path: '/master/golongan-obat',
    name: 'GolonganObat',
    component: () =>
      import(
        /* webpackChunkName: "master" */ '@/views/master/GolonganObat.vue'
      ),
    meta: { auth: true, title: 'Golongan obat' },
  },
  {
    path: '/master/identitas-perusahaan',
    name: 'IdentitasPerusahaan',
    component: () =>
      import(
        /* webpackChunkName: "master" */ '@/views/master/IdentitasPerusahaan.vue'
      ),
    meta: { auth: true, title: 'Identitas perusahaan' },
  },
  {
    path: '/master/kelompok-pelanggan',
    name: 'KelompokPelanggan',
    component: () =>
      import(
        /* webpackChunkName: "master" */ '@/views/master/KelompokPelanggan.vue'
      ),
    meta: { auth: true, title: 'Kelompok pelanggan' },
  },
  {
    path: '/master/kelompok-supplier',
    name: 'KelompokSupplier',
    component: () =>
      import(
        /* webpackChunkName: "master" */ '@/views/master/KelompokSupplier.vue'
      ),
    meta: { auth: true, title: 'Kelompok supplier' },
  },
  {
    path: '/master/komisi',
    name: 'Komisi',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/Komisi.vue'),
    meta: { auth: true, title: 'Komisi' },
  },
  {
    path: '/master/komposisi-obat',
    name: 'komposisiObat',
    component: () =>
      import(
        /* webpackChunkName: "master" */ '@/views/master/KomposisiObat.vue'
      ),
    meta: { auth: true, title: 'Komposisi obat' },
  },
  {
    path: '/master/konversi-satuan',
    name: 'UnitConverter',
    component: () =>
      import(
        /* webpackChunkName: "master" */ '@/views/master/KonversiSatuan.vue'
      ),
    meta: { auth: true, title: 'Konversi Satuan' },
  },
  {
    path: '/master/margin-global',
    name: 'MarginGlobal',
    component: () =>
      import(
        /* webpackChunkName: "master" */ '@/views/master/MarginGlobal.vue'
      ),
    meta: { auth: true, title: 'Margin Global' },
  },
  {
    path: '/master/obat',
    name: 'Obat',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/obat/Index.vue'),
    meta: { auth: true, title: 'Obat' },
  },
  {
    path: '/master/obat/add',
    name: 'ObatAdd',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/obat/Set.vue'),
    meta: { auth: true, title: 'Obat' },
  },
  {
    path: '/master/obat/edit/:id',
    name: 'ObatEdit',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/obat/Set.vue'),
    meta: { auth: true, title: 'Obat' },
  },
  {
    path: '/master/pabrik',
    name: 'Pabrik',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/Pabrik.vue'),
    meta: { auth: true, title: 'Pabrik' },
  },
  {
    path: '/master/pelanggan',
    name: 'Pelanggan',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/Pelanggan.vue'),
    meta: { auth: true, title: 'Pelanggan' },
  },
  {
    path: '/master/role',
    name: 'Role',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/Role.vue'),
    meta: { auth: true, title: 'Role' },
  },
  {
    path: '/master/salesman',
    name: 'Salesman',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/Salesman.vue'),
    meta: { auth: true, title: 'Salesman' },
  },
  {
    path: '/master/setting',
    name: 'Setting',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/Setting.vue'),
    meta: { auth: true, title: 'Seting aplikasi' },
  },
  {
    path: '/master/supplier',
    name: 'Supplier',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/Supplier.vue'),
    meta: { auth: true, title: 'Supplier' },
  },
  {
    path: '/master/user',
    name: 'User',
    component: () =>
      import(/* webpackChunkName: "master" */ '@/views/master/User.vue'),
    meta: { auth: true, title: 'User' },
  },
  {
    path: '/pembelian/faktur',
    name: 'PurchaseInvoice',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/faktur/Index.vue'
      ),
    meta: { auth: true, title: 'Faktur Pembelian' },
  },
  {
    path: '/pembelian/faktur/add',
    name: 'PurchaseInvoiceAdd',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/faktur/Set.vue'
      ),
    meta: { auth: true, title: 'Faktur Pembelian' },
  },
  {
    path: '/pembelian/faktur/edit/:id',
    name: 'PurchaseInvoiceEdit',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/faktur/Set.vue'
      ),
    meta: { auth: true, title: 'Faktur Pembelian' },
  },
  {
    path: '/pembelian/kontra-bon',
    name: 'Kontrabon',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/kontrabon/Index.vue'
      ),
    meta: { auth: true, title: 'Kontra bon supplier' },
  },
  {
    path: '/pembelian/kontra-bon/add',
    name: 'KontrabonAdd',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/kontrabon/Set.vue'
      ),
    meta: { auth: true, title: 'Kontra bon supplier' },
  },
  {
    path: '/pembelian/kontra-bon/edit/:id',
    name: 'KontrabonEdit',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/kontrabon/Set.vue'
      ),
    meta: { auth: true, title: 'Kontra bon supplier' },
  },
  {
    path: '/pembelian/pembayaran',
    name: 'PembayaranHutang',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/pembayaran/Index.vue'
      ),
    meta: { auth: true, title: 'Pembayaran Hutang' },
  },
  {
    path: '/pembelian/pembayaran/add',
    name: 'PembayaranHutangAdd',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/pembayaran/Set.vue'
      ),
    meta: { auth: true, title: 'Pembayaran Hutang' },
  },
  {
    path: '/pembelian/pembayaran/edit/:id',
    name: 'PembayaranHutangEdit',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/pembayaran/Set.vue'
      ),
    meta: { auth: true, title: 'Pembayaran Hutang' },
  },
  {
    path: '/pembelian/purchase-order',
    name: 'PO',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/purchase-order/Index.vue'
      ),
    meta: { auth: true, title: 'Purchase Order' },
  },
  {
    path: '/pembelian/purchase-order/add',
    name: 'POAdd',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/purchase-order/Set.vue'
      ),
    meta: { auth: true, title: 'Purchase Order' },
  },
  {
    path: '/pembelian/purchase-order/edit/:id',
    name: 'POEdit',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/purchase-order/Set.vue'
      ),
    meta: { auth: true, title: 'Purchase Order' },
  },
  {
    path: '/pembelian/retur',
    name: 'PurchaseReturn',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/retur/Index.vue'
      ),
    meta: { auth: true, title: 'Retur pembelian' },
  },
  {
    path: '/pembelian/retur/add',
    name: 'PurchaseReturnAdd',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/retur/Set.vue'
      ),
    meta: { auth: true, title: 'Retur pembelian' },
  },
  {
    path: '/pembelian/rekomendasi',
    name: 'Rekomendasi',
    component: () =>
      import(
        /* webpackChunkName: "rekomendasi" */ '@/views/pembelian/rekomendasi/Index.vue'
      ),
    meta: { auth: true, title: 'Rekomendasi Pembelian' },
  },
  {
    path: '/pembelian/retur/edit/:id',
    name: 'PurchaseReturnEdit',
    component: () =>
      import(
        /* webpackChunkName: "pembelian" */ '@/views/pembelian/retur/Set.vue'
      ),
    meta: { auth: true, title: 'Retur pembelian' },
  },
  {
    path: '/penjualan/kontra-bon',
    name: 'KontrabonPelanggan',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/kontrabon/Index.vue'
      ),
    meta: { auth: true, title: 'Kontra bon pelanggan' },
  },
  {
    path: '/penjualan/kontra-bon/add',
    name: 'KontrabonPelangganAdd',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/kontrabon/Set.vue'
      ),
    meta: { auth: true, title: 'Kontra bon pelanggan' },
  },
  {
    path: '/penjualan/kontra-bon/edit/:id',
    name: 'KontrabonPelangganEdit',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/kontrabon/Set.vue'
      ),
    meta: { auth: true, title: 'Kontra bon pelanggan' },
  },
  {
    path: '/penjualan/pembayaran',
    name: 'PembayaranPiutang',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/pembayaran/Index.vue'
      ),
    meta: { auth: true, title: 'Pembayaran Piutang' },
  },
  {
    path: '/penjualan/pembayaran/add',
    name: 'PembayaranPiutangAdd',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/pembayaran/Set.vue'
      ),
    meta: { auth: true, title: 'Pembayaran Piutang' },
  },
  {
    path: '/penjualan/pembayaran/edit/:id',
    name: 'PembayaranPiutangEdit',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/pembayaran/Set.vue'
      ),
    meta: { auth: true, title: 'Pembayaran Piutang' },
  },
  {
    path: '/penjualan/sales-order',
    name: 'SalesOrder',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/sales-order/Index.vue'
      ),
    meta: { auth: true, title: 'Sales Order' },
  },
  {
    path: '/penjualan/sales-order/add',
    name: 'SalesOrderAdd',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/sales-order/Set.vue'
      ),
    meta: { auth: true, title: 'Sales Order' },
  },
  {
    path: '/penjualan/sales-order/edit/:id',
    name: 'SalesOrderEdit',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/sales-order/Set.vue'
      ),
    meta: { auth: true, title: 'Sales Order' },
  },
  {
    path: '/penjualan/faktur',
    name: 'SalesInvoice',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/faktur/Index.vue'
      ),
    meta: { auth: true, title: 'Faktur Penjualan' },
  },
  {
    path: '/penjualan/faktur/add',
    name: 'SalesInvoiceAdd',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/faktur/Set.vue'
      ),
    meta: { auth: true, title: 'Faktur Penjualan' },
  },
  {
    path: '/penjualan/faktur/edit/:id',
    name: 'SalesInvoiceEdit',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/faktur/Set.vue'
      ),
    meta: { auth: true, title: 'Faktur Penjualan' },
  },
  {
    path: '/penjualan/retur',
    name: 'SalesReturn',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/retur/Index.vue'
      ),
    meta: { auth: true, title: 'Retur penjualan' },
  },
  {
    path: '/penjualan/retur/add',
    name: 'SalesReturnAdd',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/retur/Set.vue'
      ),
    meta: { auth: true, title: 'Retur penjualan' },
  },
  {
    path: '/penjualan/retur/edit/:id',
    name: 'SalesReturnEdit',
    component: () =>
      import(
        /* webpackChunkName: "penjualan" */ '@/views/penjualan/retur/Set.vue'
      ),
    meta: { auth: true, title: 'Retur penjualan' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta: { auth: true, title: 'User profile' },
  },
  {
    path: '/pajak/faktur-pajak-keluaran',
    name: 'FakturPajakKeluaran',
    component: () => import('@/views/pajak/faktur-pajak-keluaran/Index.vue'),
    meta: {
      auth: true,
      title: 'Faktur Pajak Keluaran',
    },
  },
  {
    path: '/pajak/faktur-pajak-masukan',
    name: 'FakturPajakMasukan',
    component: () => import('@/views/pajak/faktur-pajak-masukan/Index.vue'),
    meta: {
      auth: true,
      title: 'Faktur Pajak Masukan',
    },
  },
  {
    path: '/pajak/seri',
    name: 'PajakSeri',
    component: () => import('@/views/pajak/seri/Index.vue'),
    meta: { auth: true, title: 'No Seri Faktur Pajak' },
  },
  {
    path: '/pajak/seri-input-faktur',
    name: 'InputFakturPajak',
    component: () => import('@/views/pajak/seri-input-faktur/Index.vue'),
    meta: {
      auth: true,
      title: 'Input Faktur Pajak',
    },
  },
  {
    path: '/pajak/rekonsiliasi-penjualan',
    name: 'RekonsiliasiPenjualan',
    component: () => import('@/views/pajak/rekonsiliasi-penjualan/Index.vue'),
    meta: {
      auth: true,
      title: 'Rekonsiliasi Faktur Keluaran',
    },
  },
  {
    path: '/pajak/rekonsiliasi-pembelian',
    name: 'RekonsiliasiPembelian',
    component: () => import('@/views/pajak/rekonsiliasi-pembelian/Index.vue'),
    meta: {
      auth: true,
      title: 'Rekonsiliasi Faktur Masukan',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  window.document.title = to.meta.title + ' - ' + process.env.VUE_APP_APP_NAME

  if (to.matched.some((item) => item.meta.auth)) {
    if (localStorage.getItem('aid')) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (to.path) {
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
