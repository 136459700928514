import { defineStore } from 'pinia'
import http from '@/clients/Axios'
import { isEmail, isNumber } from '@/helpers'
import { useStorage } from '@vueuse/core'
import defineAbilitiesFor from '@/services/rules'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    user: useStorage('user', {}),
  }),
  getters: {
    isAuthenticated() {
      return this.user ? true : false
    },
  },
  actions: {
    async login(login, password, token_device, ability) {
      let loginForm = null

      if (isNumber(login)) {
        loginForm = {
          phone: login,
          password: password,
          token_device: token_device,
        }
      } else if (isEmail(login)) {
        loginForm = {
          email: login,
          password: password,
          token_device: token_device,
        }
      } else {
        loginForm = {
          username: login,
          password: password,
          token_device: token_device,
        }
      }

      await http.post('/user/login', loginForm).then((res) => {
        if (res.data.message === 'OK') {
          localStorage.setItem('aid', res.data.data)
          http.get('/user/index').then((res) => {
            if (res.data.message === 'OK') {
              this.user = res.data.data
              ability.update(defineAbilitiesFor(this.user.permissions))
            }
          })
        }
      })
    },
    refreshPermission(ability) {
      if (
        this.user &&
        this.user.permissions &&
        this.user.permissions.length > 0
      ) {
        ability.update(defineAbilitiesFor(this.user.permissions))
      }
    },
    logout() {
      localStorage.clear()
      this.user = null
    },
  },
})
