import { Ability, AbilityBuilder } from '@casl/ability'

export default function defineAbilitiesFor(permissions) {
  const { can, rules } = new AbilityBuilder(Ability)

  permissions.forEach((el) => {
    can(el)
  })

  return rules
}
