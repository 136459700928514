import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import workbox from './registerServiceWorker'
import router from './router'
import PrimeVue from 'primevue/config'
import { abilitiesPlugin } from '@casl/vue'
import ability from './services/ability'
import { messaging } from './firebase'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import './assets/pbf.css'
import './assets/styles.css'

import AutoComplete from 'primevue/autocomplete'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Image from 'primevue/image'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Menu from 'primevue/menu'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import OverlayPanel from 'primevue/overlaypanel'
import RadioButton from 'primevue/radiobutton'
import SelectButton from 'primevue/selectbutton'
import Skeleton from 'primevue/skeleton'
import SplitButton from 'primevue/splitbutton'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import Toolbar from 'primevue/toolbar'
import FileUpload from 'primevue/fileupload'

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.use(PrimeVue)
app.use(ToastService)
app.use(abilitiesPlugin, ability)
app.provide('workbox', workbox)
app.provide('messaging', messaging)
app.provide('ability', ability)

app.component('AutoComplete', AutoComplete)
app.component('Calendar', Calendar)
app.component('Card', Card)
app.component('Chip', Chip)
app.component('Checkbox', Checkbox)
app.component('Column', Column)
app.component('Button', Button)
app.component('DataTable', DataTable)
app.component('Dialog', Dialog)
app.component('Divider', Divider)
app.component('Dropdown', Dropdown)
app.component('Image', Image)
app.component('InputNumber', InputNumber)
app.component('InputText', InputText)
app.component('InputSwitch', InputSwitch)
app.component('Menu', Menu)
app.component('Message', Message)
app.component('MultiSelect', MultiSelect)
app.component('OverlayPanel', OverlayPanel)
app.component('RadioButton', RadioButton)
app.component('SelectButton', SelectButton)
app.component('Skeleton', Skeleton)
app.component('SplitButton', SplitButton)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Textarea', Textarea)
app.component('Toast', Toast)
app.component('Toolbar', Toolbar)
app.component('FileUpload', FileUpload)

app.directive('tooltip', Tooltip)
app.directive('badge', BadgeDirective)

app.mount('#app')
