<template>
  <div>
    <div class="mb-4 flex align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Home</span>
    </div>
    <div class="card bg-white mb-3">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-3">
          <div class="mb-2">
            <div>
              <span class="block font-medium mb-3">Pembelian</span>
              <div class="text-2xl font-bold">
                {{ formatCurrency(total_pembelian) }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
          <div class="mb-2">
            <div>
              <span class="block font-medium mb-3">Penjualan</span>
              <div class="text-2xl font-bold">
                {{ formatCurrency(total_penjualan) }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
          <div class="mb-2">
            <div>
              <span class="block font-medium mb-3">Hutang</span>
              <div class="text-2xl font-bold">
                {{ formatCurrency(total_hutang) }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
          <div class="mb-2">
            <div>
              <span class="block font-medium mb-3">Piutang</span>
              <div class="text-2xl font-bold">
                {{ formatCurrency(total_piutang) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card bg-white mb-3 col-12">
      <div class="text-xl mb-3">Penjualan</div>
      <div id="chartTotalSales"></div>
    </div>
    <!-- v-if="$ability.can('view salesman sales')"  -->
    <div class="card bg-white mb-3">
      <div class="text-xl mb-3">Penjualan berdasarkan salesman</div>
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-9">
          <div id="chartTotalSalesBySalesman"></div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
          <DataTable :value="top_salesman" class="p-datatable-sm">
            <Column field="salesman" header="Nama"></Column>
            <Column field="total" header="Sales" dataType="numeric">
              <template #body="{ data }">
                <div class="text-right">{{ formatCurrency(data.total) }}</div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-4">
          <div class="card bg-white">
            <div class="block text-xl">Faktur Penjualan (Jatuh Tempo)</div>
            <div
              class="bg-green-500 border-round overflow-hidden w-3rem mt-2"
              style="height: 3px"
            />
            <DataTable :value="penjualan_jatuh_tempo" class="p-datatable-sm">
              <Column field="nomor_faktur" header="No Faktur"></Column>
              <Column field="top_at" header="Tanggal">
                <template #body="{ data }">
                  {{ formatDate(data.top_at) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
          <div class="card bg-white">
            <div class="block text-xl">Faktur Pembelian (Jatuh Tempo)</div>
            <div
              class="bg-green-500 border-round overflow-hidden w-3rem mt-2"
              style="height: 3px"
            />
            <DataTable :value="pembelian_jatuh_tempo" class="p-datatable-sm">
              <Column field="nomor_faktur" header="No Faktur"></Column>
              <Column field="top_at" header="Tanggal">
                <template #body="{ data }">
                  {{ formatDate(data.top_at) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
          <div class="card bg-white">
            <div class="block text-xl">Masa berlaku SIA/SIPA akan habis</div>
            <div
              class="bg-green-500 border-round overflow-hidden w-3rem mt-2"
              style="height: 3px"
            />
            <DataTable :value="pelanggan_expired" class="p-datatable-sm">
              <Column field="nama" header="Nama"></Column>
              <Column field="sia_validity" header="SIA">
                <template #body="{ data }">
                  {{ formatDate(data.sia_validity) }}
                </template>
              </Column>
              <Column field="sipa_validity" header="SIPA">
                <template #body="{ data }">
                  {{ formatDate(data.sipa_validity) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from 'frappe-charts/dist/frappe-charts.min.esm'
import 'frappe-charts/dist/frappe-charts.min.css'
import { formatCurrency, formatDate } from '@/helpers'
import http from '@/clients/Axios'
import errorHandler from '@/helpers/error-handler'

// import { ABILITY_TOKEN } from '@casl/vue'

export default {
  name: 'Home',
  /* inject: {
    $ability: { from: ABILITY_TOKEN },
  }, */
  data() {
    return {
      total_pembelian: 0,
      total_penjualan: 0,
      total_hutang: 0,
      total_piutang: 0,
      top_salesman: [],
      pembelian_jatuh_tempo: [],
      penjualan_jatuh_tempo: [],
      pelanggan_expired: [],
      penjualan_bulan: [],
      penjualan_total: [],
      penjualan_salesman: [],
      salesCounter: {
        labels: [],
        datasets: [
          {
            type: 'bar',
            values: [],
          },
        ],
      },
      salesmanSalesCounter: {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            type: 'bar',
            values: [],
          },
        ],
      },
    }
  },
  mounted() {
    http
      .get(`/dashboard`)
      .then((res) => {
        if (res.data.status === 200) {
          this.top_salesman = res.data.top_salesman
          this.total_pembelian = res.data.total.total_pembelian
          this.total_penjualan = res.data.total.total_penjualan
          this.total_hutang = res.data.total.total_hutang
          this.total_piutang = res.data.total.total_piutang
          this.pembelian_jatuh_tempo = res.data.jatuh_tempo.pembelian
          this.penjualan_jatuh_tempo = res.data.jatuh_tempo.penjualan
          this.pelanggan_expired = res.data.pelanggan_expired
          this.penjualan_total = res.data.penjualan_bulan
          this.penjualan_salesman = res.data.penjualan_salesman

          this.salesCounter.labels = res.data.penjualan_bulan.map(function (a) {
            return a.nama_bulan
          })

          this.salesCounter.datasets.forEach((item) => {
            item.values = res.data.penjualan_bulan.map(function (b) {
              return b.total
            })
          })

          new Chart('#chartTotalSales', {
            data: this.salesCounter,
            type: 'line',
            height: 250,
            colors: ['#743ee2'],
            tooltipOptions: {
              formatTooltipX: (d) => (d + '').toUpperCase(),
              formatTooltipY: (d) => formatCurrency(d),
            },
          })

          this.salesmanSalesCounter.datasets = res.data.penjualan_salesman.map(
            (a) => {
              return {
                name: a.nama,
                type: 'bar',
                values: a.total,
              }
            }
          )

          new Chart('#chartTotalSalesBySalesman', {
            data: this.salesmanSalesCounter,
            type: 'line',
            height: 250,
            colors: ['#743ee2'],
            tooltipOptions: {
              formatTooltipX: (d) => (d + '').toUpperCase(),
              formatTooltipY: (d) => formatCurrency(d),
            },
          })
        }
      })

      .catch((err) => {
        errorHandler(err, 'Dashboard', this)
      })
  },
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
